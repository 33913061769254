import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Full Snatch 2-2-2-2\\@90% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`10:00 EMOM of:`}</p>
    <p>{`1-Snatch Grip DL to Hang`}</p>
    <p>{`1-Hang Squat Snatch`}</p>
    <p>{`1-OHS`}</p>
    <p><em parentName="p">{`*`}{`using 75% 1RM of full snatch weight`}</em></p>
    <p>{`then at minute 12:00…`}</p>
    <p>{`3 rounds for time of:`}</p>
    <p>{`20-OHS (95/65)`}</p>
    <p>{`10-Burpees Over Bar`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our monthly free class is this Saturday so bring a friend!  The
Ville at 8:00 or 9:00am and East at 10:00 or 11:00am.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Personal Training, Elements, and Massage Gift Certificates now
available for the Holidays!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our 2nd Annual Pre Open in house competition will start January 1st
so great ready! RX Men, RX Women, scaled, and masters (50+) divisions.
More details to come.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      